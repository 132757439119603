import {
  Box, Button, Divider, Menu, Stack, Typography,
} from '@mui/material';
import Icon from '@mui/material/Icon';
import { s } from 'i18n';
import React, {
  MutableRefObject, useCallback, useState,
} from 'react';
import { fetcher } from 'graphql-api/fetcher';
import { GridRefType, useRequest } from '@xeebi/neru';
import { MessageStatConfig, Maybe } from 'graphql-api';
import { ConfirmDlg, useConfirmation } from 'shared-scope/components/ConfirmDlg';
import forOwn from 'lodash/forOwn';
import { pick } from 'lodash';
import ViewSaveModal, { COLORS } from './ViewSaveModal';
import {
 StatisticView, StatisticViewAvail, StatisticViewForm, ViewParams,
} from '../../types';
import { getSdk } from '../../queries.generated';
import SingleView from './SingleView';

const api = getSdk(fetcher);

const defaultSavedView: StatisticViewForm = {
    color: COLORS[0],
    [StatisticViewAvail.sorting]: true,
    [StatisticViewAvail.filter]: true,
    [StatisticViewAvail.columns]: true,
    [StatisticViewAvail.content]: true,
  };

export default function ViewSave({
 views, selected, onClick, currentViewParams, gridRef, onChangeViews,
}: ViewSaveProps) {
  const [openModal, setOpenModal] = useState(false);
  const [savedView, setSavedView] = useState<StatisticViewForm>({});
  const [anchorEl, setAnchorEl] = useState<Maybe<HTMLElement>>(null);
  const [loadingViews, setLoadingViews] = useState<number[]>([]);

  const [showConfirm, hideConfirm, confirmParams] = useConfirmation();
  const { fetch: fetchSaveView } = useRequest(api.saveMessageStatConfig, { cancelPrevious: false });
  const { fetch: fetchDeleteView } = useRequest(api.deleteMessageStatConfig, { cancelPrevious: false });

  const openSaveModal = useCallback((initial: StatisticViewForm) => {
    setSavedView(initial);
    setOpenModal(true);
  }, []);

  const saveView = useCallback(async () => {
    const gridSettings = gridRef.current.exportState();

    const avail: StatisticViewAvail[] = [];
    forOwn(savedView, (v, k) => {
      if (v === true) {
        avail.push(k as StatisticViewAvail);
      }
    });

    const config: StatisticView = {
      color: savedView.color,
      content: currentViewParams,
      ...pick(gridSettings, ['filter', 'sorting', 'columns']),
      avail,
    };

    await fetchSaveView({
      filter: savedView.viewId ? JSON.stringify({ id: { $eq: savedView.viewId } }) : '',
      input: {
        name: savedView.title,
        config: JSON.stringify(config),
      },
    });
    onChangeViews();
  }, [savedView, currentViewParams, gridRef, fetchSaveView, onChangeViews]);

  const deleteView = useCallback(async (view: MessageStatConfig) => {
    showConfirm(s('Are you sure you want to delete :viewName?', { viewName: view.name }),
      async (confirmed: boolean) => {
        hideConfirm();
        if (confirmed && view.id) {
          setLoadingViews((l) => (view.id ? l.concat(view.id) : l));
          await fetchDeleteView({ filter: JSON.stringify({ id: view.id }) });
          onChangeViews();
          setLoadingViews((l) => l.filter((ll) => ll !== view.id));
        }
      });
  }, [fetchDeleteView, onChangeViews, showConfirm, hideConfirm]);

  const editView = useCallback(async (view: MessageStatConfig) => {
    const config = JSON.parse(view?.config || '') as StatisticView;
    view.id && openSaveModal({
      viewId: view.id,
      title: view.name || '',
      color: config.color,
      [StatisticViewAvail.sorting]: config.avail.includes(StatisticViewAvail.sorting),
      [StatisticViewAvail.filter]: config.avail.includes(StatisticViewAvail.filter),
      [StatisticViewAvail.columns]: config.avail.includes(StatisticViewAvail.columns),
      [StatisticViewAvail.content]: config.avail.includes(StatisticViewAvail.content),
    });
  }, [openSaveModal]);

  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (<>
    <Button
      variant="text"
      size="medium"
      aria-label="save-view"
      id="save-view-button"
      aria-controls={openMenu ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={openMenu ? 'true' : undefined}
      onClick={handleClick}
    >
      <Icon className="icon-save-view" sx={{ fontSize: '24px' }} />
    </Button>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <Box
        sx={{
          width: '350px',
          padding: '8px 16px',
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box><Typography variant="h3">{s('Save view')}</Typography></Box>
          <Button onClick={() => openSaveModal(defaultSavedView)}>{s('Save view')}</Button>
        </Stack>
        <Divider sx={{ margin: '10px 0 5px' }} />
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box><Typography variant="h6">{s('Saved')}</Typography></Box>
          <Box><Typography variant="subtitle1">{views.length}</Typography></Box>
        </Stack>
        <Stack spacing={1} sx={{ marginTop: '5px', maxHeight: '500px' }}>
          {views.map((v) => (
            <SingleView
              view={v}
              loading={!!v.id && loadingViews.includes(v.id)}
              key={v.id}
              onDelete={() => v.id && deleteView(v)}
              onEdit={() => editView(v)}
              onClick={() => {
                onClick(v);
                handleClose();
              }}
              selected={v.id === selected}
            />
          ))}
        </Stack>
      </Box>
    </Menu>
    <ViewSaveModal
      title={s('Save view')}
      open={openModal}
      onClose={() => setOpenModal(false)}
      onSave={() => {
        saveView();
        setOpenModal(false);
        handleClose();
      }}
      view={savedView}
      onChangeView={(v) => setSavedView(v)}
    />
    <ConfirmDlg
      title={s('Are you sure?')}
      confirmParams={confirmParams}
      maxWidth="lg"
    />
  </>);
}

type ViewSaveProps = {
  views: MessageStatConfig[]
  currentViewParams: ViewParams
  gridRef: MutableRefObject<GridRefType>
  onChangeViews: () => void
  selected: Maybe<MessageStatConfig>
  onClick: (v: Maybe<MessageStatConfig>) => void
};
