import {
  ApiQueryParams, ApiQueryResult, DataGrid, useGridRef, useRequest, useLocalStorage, GridColumn,
} from '@xeebi/neru';
import { fetcher } from 'graphql-api/fetcher';
import {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import useAlert from 'shared-scope/hooks/useAlert';
import { Stack } from '@mui/material';
import muiStruct from 'shared-scope/helpers/muiStruct';
import { AxiosRequestConfig } from 'axios';
import {
 omit, sortBy, pick,
} from 'lodash';
import { Maybe, MessageStatConfig } from 'graphql-api';
import { GetMessageStatConfigQuery, getSdk } from 'products/Account/Tabs/Statistics/queries.generated';
import { Export, ViewSave, ViewList } from 'products/Account/Tabs/Statistics/components';
import { DATE_FORMAT, getParams } from 'products/Account/Tabs/Statistics/helpers';
import {
 ColumnGroup, StatisticColumn, StatisticView, StatisticViewAvail,
} from 'products/Account/Tabs/Statistics/types';
import useStatisticForm from './useStatisticForm';
import Struct from './Struct';


const api = getSdk(fetcher);
const MViewSave = memo(ViewSave);
const MViewList = memo(ViewList);

const GRID_STORAGE_ID = 'StatisticsGridStorage';

export default function MessagesView() {
  const gridRef = useGridRef();
  const { addError } = useAlert();

  const {
    statFormRender,
    viewParams,
    statFormRow,
    setViewParams,
  } = useStatisticForm(Struct, DATE_FORMAT);

  const {
    error,
    fetch: fetchSavedViews,
    result: savedViews,
  } = useRequest<GetMessageStatConfigQuery>(api.getMessageStatConfig, { quietUpdate: true });

  const [
    viewsRating,
    setViewsRating,
  ] = useLocalStorage<Record<number, number>>('Statistic.ViewsRating', {});
  const [
    selectedSavedView,
    setSelectedSavedView,
  ] = useState<Maybe<MessageStatConfig>>(null);

  const loadStatistics = useCallback(async (params: ApiQueryParams, options: AxiosRequestConfig) => {
    const {
      messageStat,
      messageStatCount,
      messageStatTotal,
    } = await api.getMessageStat({
      ...params,
      ...omit(viewParams, 'dataColumns'),
    }, options);

    return {
      count: messageStatCount,
      rows: messageStat,
      total: messageStatTotal,
    } as ApiQueryResult;
  }, [viewParams]);

  const exportParams = useCallback(() => getParams(viewParams, gridRef), [viewParams, gridRef]);

  const applySavedView = useCallback((savedView: Maybe<MessageStatConfig>) => {
    savedView && setViewsRating(
      (vr) => {
        vr = vr || {};
        return savedView.id ? { ...vr, [savedView.id]: vr[savedView.id] + 1 } : vr;
      },
    );
    setSelectedSavedView(savedView);

    const config = JSON.parse(savedView?.config || '') as StatisticView;
    config.avail.includes(StatisticViewAvail.content) && config.content
      ? setViewParams(config.content) : setViewParams(null);

    // If you need set empty state for unavailable params
    // const gridState = {
    //   filter: config.avail.includes(StatisticViewAvail.filter)
    //     ? config[StatisticViewAvail.filter] : { filterModel: { items: [] } },
    //   sorting: config.avail.includes(StatisticViewAvail.sorting)
    //     ? config[StatisticViewAvail.sorting] : { sortModel: [] },
    //   columns: config.avail.includes(StatisticViewAvail.columns)
    //     ? config[StatisticViewAvail.columns] : {},
    // };

    // If you DON'T need set empty state for unavailable params
    gridRef.current.restoreState(pick(config, config.avail));
  }, [gridRef, setViewParams, setViewsRating]);

  const gridColumns = useMemo(
    () => {
      const getStruct = (cols: StatisticColumn[] = []) => {
        const colsFields = cols.map((c) => c.field);
        return Struct().filter((c) => colsFields.includes(c.field));
      };

      return [
        ...Struct(ColumnGroup.common),
        ...getStruct(statFormRow.generalColumns),
        ...getStruct(statFormRow.customersColumns),
        ...getStruct(statFormRow.messagesColumns),
        ...getStruct(statFormRow.billingColumns),
      ];
    },
    [statFormRow.generalColumns, statFormRow.customersColumns, statFormRow.messagesColumns, statFormRow.billingColumns],
  );

  const sortedViews = useMemo(
    () => sortByRating(savedViews?.messageStatConfig || [], viewsRating),
    [savedViews?.messageStatConfig, viewsRating],
  );

  useEffect(() => {
    fetchSavedViews();
  }, [fetchSavedViews]);

  /**
   * Refresh grid
   */
  useEffect(() => {
    gridRef.current.refresh();
  }, [gridColumns, loadStatistics, gridRef]);

  useEffect(() => {
    error && addError(error.getMessage());
  }, [addError, error]);

  return (
    <Stack spacing={3}>
      {statFormRender}
      <DataGrid
        storageId={GRID_STORAGE_ID}
        pageSize={10}
        columns={muiStruct(gridColumns as GridColumn[])}
        getRows={loadStatistics}
        apiRef={gridRef}
        customTools={[
          <MViewSave
            key="viewmodal"
            views={sortedViews}
            currentViewParams={viewParams}
            gridRef={gridRef}
            onChangeViews={() => fetchSavedViews()}
            selected={selectedSavedView}
            onClick={applySavedView}
          />,
          <MViewList
            key="viewlist"
            views={sortedViews}
            selected={selectedSavedView}
            onClick={applySavedView}
          />,
          <Export key="export" params={exportParams} type="messages" />,
        ]}
        onError={() => {}}
        // columnBuffer={30}
        nullRender="-"
      />
    </Stack>
  );
}

const sortByRating = (arr: MessageStatConfig[], rating: Record<number, number>) => {
  const ratingArr = arr.map(
    (item) => (
      {
        rating: item.id ? rating[item.id] : 0,
        stat: item,
      }
    ));

  return sortBy(ratingArr, 'rating').map((item) => item.stat).reverse();
};


