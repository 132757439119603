import {
  Radio, RadioGroup, RadioProps, Stack, styled, Typography,
} from '@mui/material';

export default function ColoredRadioGroup({
 colors, value, onChange, title, error,
}: ColoredRadioGroupProps) {
  return (
    <Stack spacing={1}>
      <Typography variant="body1">{title}</Typography>
      {error && <Typography variant="subtitle1" color={({ palette }) => palette.error.main}>{error}</Typography>}
      <RadioGroup
        row
        defaultValue="female"
        aria-labelledby="colored-radios"
        value={value}
        onChange={(e, v) => onChange(v)}
      >
        {colors.map((c) => <BpRadio value={c} radioColor={c} key={c} />)}
      </RadioGroup>
    </Stack>
  );
}

function BpRadio({ radioColor, ...props }: RadioProps & BpRadioProps) {
  return (
    <Radio
      color="default"
      checkedIcon={<BpCheckedIcon color={radioColor} />}
      icon={<BpIcon color={radioColor} />}
      {...props}
    />
  );
}

type ColoredRadioGroupProps = {
  title: string
  colors: string[]
  value: string
  onChange: (v: string) => void
  error?: string
};

type BpRadioProps = {
  radioColor: string
};

const BpIcon = styled('span')(({ color }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: color,
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ color }) => ({
  backgroundColor: color,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: color,
  },
}));
