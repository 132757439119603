import React from 'react';
import { s } from 'i18n';
import { cloneDeep, find, sum } from 'lodash';
import { Campaign, CampaignStatus as CampaignStatusApi, Maybe } from 'graphql-api';
import {
  CampaignETA,
  CampaignStatus,
  CampaignStatuses,
  GridType,
  ImprovedCampaign,
} from 'products/CampaignList/types';
import { ToolKey, ToolKeys } from 'products/CampaignList/components/Tools';
import {
  CAMPAIGN_TYPE_RESPONDER,
  CAMPAIGN_TYPE_API_TEMPLATE,
  CAMPAIGN_TYPE_STICKY,
  CAMPAIGN_TYPE_OPT_IN,
  CAMPAIGN_TYPE_DRIP,
} from 'products/common/helpers';

export const SOURCE_TEXT = 'source-text';
export const SOURCE_FILE = 'source-file';
export const SOURCE_PHONE_BOOK = 'source-phone-book';
export const SOURCE_CAMPAIGN = 'source-campaign';
export const SOURCE_NUMBER_LOOKUP = 'source-number-lookup';
export const SOURCE_RESPONDER = 'source-responder';


export const ASC = 'asc';
export const DESC = 'desc';

export const MESSAGE_STATUS_NEW = 0;
export const MESSAGE_STATUS_SENT = 1;
export const MESSAGE_STATUS_FAILED = 2;
export const MESSAGE_STATUS_STOP_LIST = 3;
export const MESSAGE_STATUS_RESTRICTED = 4;
export const MESSAGE_STATUS_INVALID_NUMBER = 5;
export const MESSAGE_STATUS_UNROUTABLE = 6;
export const MESSAGE_STATUS_FILTERED = 7;
export const MESSAGE_CLICKED = 1001; // not a status
export const MESSAGE_STATUS_SKIPPED = 8;
export const MESSAGE_STATUS_EXPIRED = 103;

/**
 * Delivery statuses
 */
export const DELIVERY_ENROUTE = 1;
export const DELIVERY_DELIVRD = 2;
export const DELIVERY_EXPIRED = 3;
export const DELIVERY_DELETED = 4;
export const DELIVERY_UNDELIV = 5;
export const DELIVERY_ACCEPTD = 6;
export const DELIVERY_UNKNOWN = 7;
export const DELIVERY_REJECTD = 8;
export const DELIVERY_SKIPPED = 9;
export const DELIVERY_FILTERD = 10;
export const DELIVERED_STATUSES = [
  DELIVERY_DELIVRD,
];

export const UNDELIVERED_STATUSES = [
  DELIVERY_EXPIRED,
  DELIVERY_DELETED,
  DELIVERY_UNDELIV,
  DELIVERY_UNKNOWN,
  DELIVERY_REJECTD,
];

/**
 * Get statuses
 * @return {*[]}
 */
export function getStatuses(): {
  status_id: number
  status_name: string
  filter?: Record<string, any>
  resend?: boolean
}[] {
  return [
    {
      status_id: -1,
      status_name: 'All',
    },
    {
      status_id: MESSAGE_STATUS_NEW,
      status_name: 'New',
    },
    {
      status_id: MESSAGE_STATUS_SENT,
      status_name: 'Sent',
    },
    {
      status_id: MESSAGE_STATUS_FAILED,
      status_name: 'Failed',
      resend: true,
    },
    {
      status_id: MESSAGE_STATUS_STOP_LIST,
      status_name: 'Stop list',
      resend: true,
    },
    {
      status_id: MESSAGE_CLICKED,
      filter: { click_ts: { $neq: null } },
      status_name: 'Clicked',
    },
    {
      status_id: MESSAGE_STATUS_RESTRICTED,
      status_name: 'Restricted',
    },
    {
      status_id: MESSAGE_STATUS_UNROUTABLE,
      status_name: 'Unroutable',
      resend: true,
    },
    {
      status_id: MESSAGE_STATUS_INVALID_NUMBER,
      status_name: 'Invalid number',
    },
  ];
}

export function getResendStatuses() {
  return getStatuses().filter((st) => st.resend);
}

export function getStatusData(key: string) {
  return find(getStatuses(), { k: key });
}

export function getStatusStyle(status: CampaignStatusApi | null, statusString: string | null) {
  const statusId = status?.id as CampaignStatuses;
  const cls = ['badge'];
  if (status) {
    if (statusId === CampaignStatus.Stopped && statusString !== null) {
      cls.push('bg-danger');
    } else {
      const classes: { [key in number]: string } = {
        [CampaignStatus.Suspended]: 'bg-warning',
        [CampaignStatus.Running]: 'bg-success',
        [CampaignStatus.Starting]: 'bg-success',
        [CampaignStatus.Archived]: 'bg-dark bg-opacity-25 text-muted',
        [CampaignStatus.Cancelled]: 'bg-danger',
        [CampaignStatus.Stopped]: 'bg-primary',
        [CampaignStatus.Stopping]: 'bg-primary',
        [CampaignStatus.WaitingForApproval]: 'bg-warning text-dark',
        [CampaignStatus.Declined]: 'bg-danger',
        [CampaignStatus.Complete]: 'bg-complete',
      };
      const addCls = classes[statusId] || '';

      addCls && cls.push(addCls);
    }
  }
  if (cls.length === 1) {
    cls.push('bg-light text-dark');
  }

  return cls.join(' ');
}

export const shortFormNumberStr = (number: number) => {
  if (number < 1000) {
    return number;
  }
  const isMillion = number >= 1_000_000;
  const index = indexOfComma(number, isMillion ? 1000 : 1);
  const shortNumber = number.toString().slice(0, 3);
  const numberWithComma = index ? `${shortNumber.slice(0, index)}.${shortNumber.slice(index)}` : shortNumber;
  return `${numberWithComma}${isMillion ? 'M' : 'K'}`;
};

const indexOfComma = (number: number, delimiter: number = 1) => {
  const numberLength = (Math.ceil(number / delimiter)).toString().length;
  return numberLength === 5 ? 2 : (numberLength === 4 ? 1 : 0);
};

export const shortFormNumber = (number: number) => {
  if (number < 1000) {
    return number;
  }
  const numberStr = shortFormNumberStr(number);
  return <span title={`${number}`}>{numberStr}</span>;
};

function* Generator(): IterableIterator<number> {
  let id = 0;
  while (true) {
    id += 1;
    yield id;
  }
}

export const idGenerator = Generator();


const inActiveStatuses = [
  CampaignStatus.Archived,
  CampaignStatus.Cancelled,
  CampaignStatus.Stopped,
  CampaignStatus.Complete,
  CampaignStatus.Suspended,
] as const;

export const isInActive = (status: CampaignStatuses) => ([...inActiveStatuses] as number[]).includes(status);

export function countETA(campaign: CampaignETA, oldCampaign: CampaignETA, now = Date.now()) {
  if (!oldCampaign || !Object.keys(oldCampaign || {}).length) {
    campaign.lastUpdateETA = now;
    return campaign;
  }
  if (campaign?.status?.id !== CampaignStatus.Running) {
    campaign.eta = undefined;
    return campaign;
  }
  const {
    lastUpdateETA,
    messagesFailedTotal: lastSmsFailed,
    messagesSentTotal: lastSmsSent,
    eta: lastETA,
    middleSpeedETA: lastMiddleSpeedETA,
  } = oldCampaign;
  const lastSmsSentTotal = (lastSmsFailed || 0) + (lastSmsSent || 0);

  const {
    messagesTotal: total,
    messagesSentTotal: newMessagesSentTotal,
    messagesFailedTotal: newMessagesFailedTotal,
  } = campaign;

  const smsSent = (newMessagesFailedTotal || 0) + (newMessagesSentTotal || 0);

  if (!lastUpdateETA) {
    campaign.lastUpdateETA = now;
    return campaign;
  }

  if (smsSent === lastSmsSentTotal) {
    campaign.lastUpdateETA = lastUpdateETA;
    campaign.eta = lastETA;
    campaign.middleSpeedETA = lastMiddleSpeedETA;
    return campaign;
  }

  const currentSpeedETA = (smsSent - lastSmsSentTotal) / (now - lastUpdateETA);
  const middleSpeedETA = (9 * (lastMiddleSpeedETA || currentSpeedETA) + currentSpeedETA) / 10;
  const estimatedCompletionTime = (((total || 0) - smsSent) / middleSpeedETA) / 1000;

  let etsHours: string | number = Math.floor(estimatedCompletionTime / 60 / 60);
  let etsMinutes: string | number = Math.floor((estimatedCompletionTime - etsHours * 60 * 60) / 60);
  let etsSeconds: string | number = Math.floor(estimatedCompletionTime % 60);
  etsHours = etsHours < 10 ? '0' + etsHours : etsHours;
  etsMinutes = etsMinutes < 10 ? '0' + etsMinutes : etsMinutes;
  etsSeconds = etsSeconds < 10 ? '0' + etsSeconds : etsSeconds;
  //set data to campaign
  campaign.lastUpdateETA = now;
  campaign.eta = `${etsHours}:${etsMinutes}:${etsSeconds}` === '00:00:00' ? undefined : `${etsHours}:${etsMinutes}:${etsSeconds}`;
  campaign.middleSpeedETA = middleSpeedETA || currentSpeedETA;
  return campaign;
}

export function getCampaignStatus(statusId: any, campaignType: GridType) {
  const statusMap = {
    [String(CampaignStatus.Starting)]: {
      id: CampaignStatus.Starting,
      name: 'Starting',
    },
    [String(CampaignStatus.Running)]: {
      id: CampaignStatus.Running,
      name: 'Running',
    },
    [String(CampaignStatus.Scheduled)]: {
      id: CampaignStatus.Scheduled,
      name: 'Scheduled',
    },
    [String(CampaignStatus.Stopping)]: {
      id: CampaignStatus.Stopping,
      name: 'Stopping',
    },
    [String(CampaignStatus.Stopped)]: {
      id: CampaignStatus.Stopped,
      name: 'Stopped',
    },
    [String(CampaignStatus.Complete)]: {
      id: CampaignStatus.Complete,
      name: campaignType === GridType.Poll ? 'All sent' : 'Complete',
    },
    [String(CampaignStatus.Archived)]: {
      id: CampaignStatus.Archived,
      name: 'Archived',
    },
    [String(CampaignStatus.Suspended)]: {
      id: CampaignStatus.Suspended,
      name: 'Suspended',
    },
    [String(CampaignStatus.Cancelling)]: {
      id: CampaignStatus.Cancelling,
      name: 'Cancelling',
    },
    [String(CampaignStatus.Cancelled)]: {
      id: CampaignStatus.Cancelled,
      name: 'Cancelled',
    },
    [String(CampaignStatus.WaitingForApproval)]: {
      id: CampaignStatus.WaitingForApproval,
      name: 'Waiting for approval',
    },
    [String(CampaignStatus.Declined)]: {
      id: CampaignStatus.Declined,
      name: 'Declined',
    },
    [String(CampaignStatus.ProcessingSchedule)]: {
      id: CampaignStatus.ProcessingSchedule,
      name: 'Processing schedule',
    },
    [String(CampaignStatus.Draft)]: {
      id: CampaignStatus.Draft,
      name: 'Draft',
    },
    [String(CampaignStatus.Approved)]: {
      id: CampaignStatus.Approved,
      name: 'Approved',
    },
  };
  return statusMap[statusId];
}


export function getCampaignStatuses(campaignType: GridType) {
  return [
    {
      id: CampaignStatus.Starting,
      name: 'Starting',
    },
    {
      id: CampaignStatus.Running,
      name: 'Running',
    },
    {
      id: CampaignStatus.Scheduled,
      name: 'Scheduled',
    },
    {
      id: CampaignStatus.Stopping,
      name: 'Stopping',
    },
    {
      id: CampaignStatus.Stopped,
      name: 'Stopped',
    },
    {
      id: CampaignStatus.Complete,
      name: campaignType === GridType.Poll ? 'All sent' : 'Complete',
    },
    {
      id: CampaignStatus.Archived,
      name: 'Archived',
    },
    {
      id: CampaignStatus.Suspended,
      name: 'Suspended',
    },
    {
      id: CampaignStatus.WaitingForApproval,
      name: 'Waiting for approval',
    },
    {
      id: CampaignStatus.Declined,
      name: 'Declined',
    },
    {
      id: CampaignStatus.ProcessingSchedule,
      name: 'Processing schedule',
    },
    {
      id: CampaignStatus.Draft,
      name: 'Draft',
    },
    {
      id: CampaignStatus.Approved,
      name: 'Approved',
    },
  ];
}

export function calcCampaignCounters(campaign: Campaign) {
  const messagesTotal = campaign.messagesTotal || 0;
  const messagesFailedTotal = campaign.messagesFailedTotal || 0;
  const messagesSentTotal = campaign.messagesSentTotal || 0;
  const messagesStopList = campaign.messagesStopList || 0;
  const messagesRestrictedContent = campaign.messagesRestrictedContent || 0;
  const messagesFailedUnroutable = campaign.messagesFailedUnroutable || 0;
  const messagesFailedInvalidNumber = campaign.messagesFailedInvalidNumber || 0;
  const messagesFailedFiltered = campaign.messagesFailedFiltered || 0;
  const dsDelivered = campaign.dsDelivered || 0;
  const dsFiltered = campaign.dsFiltered || 0;
  const dsErrors = campaign.dsErrors || 0;
  const dlrBilling = campaign.dlrBilling || 0;

  // Errors
  const errorCount = sum([
    messagesFailedTotal,
    messagesStopList,
    messagesRestrictedContent,
    messagesFailedUnroutable,
    messagesFailedInvalidNumber,
    messagesFailedFiltered,
    dlrBilling ? dsErrors + dsFiltered : 0,
  ]);

  return [
    messagesSentTotal,
    dsDelivered,
    messagesTotal,
    errorCount,
    messagesFailedUnroutable,
  ] as const;
}

export function getToolKeys(statusId: Maybe<number | undefined>, campaignType: Maybe<number | undefined>, isPoll?: boolean) {
  const id = statusId === undefined ? -1 : statusId as number;
  const updateTools: ToolKeys[] = [];
  const isApiCampaign = campaignType === CAMPAIGN_TYPE_API_TEMPLATE || campaignType === CAMPAIGN_TYPE_STICKY;
  const isResponder = campaignType === CAMPAIGN_TYPE_RESPONDER;
  const isDrip = campaignType === CAMPAIGN_TYPE_DRIP;
  const isOptIn = campaignType === CAMPAIGN_TYPE_OPT_IN;

  if (!isApiCampaign) {
    ([
      CampaignStatus.Running,
      CampaignStatus.Scheduled,
      CampaignStatus.Suspended,
      CampaignStatus.ProcessingSchedule,
    ] as number[]).includes(id)
    && updateTools.push(ToolKey.Stop);

    ([CampaignStatus.Stopped] as number[]).includes(id)
    && updateTools.push(ToolKey.Start);

    (!isResponder && !isOptIn && !isDrip && isPoll) && updateTools.push(ToolKey.ClonePoll, ToolKey.ClonePollNewParticipants);
    (!isResponder && !isOptIn && !isDrip && !isPoll) && updateTools.push(ToolKey.Clone);
    (!isResponder && !isOptIn) && updateTools.push(isPoll ? ToolKey.ClonePollNew : ToolKey.CloneNew);
  }

  (([CampaignStatus.Stopped, CampaignStatus.Complete] as number[]).includes(id)
      || (!([CampaignStatus.Archived] as number[]).includes(id) && campaignType === CAMPAIGN_TYPE_API_TEMPLATE))
  && updateTools.push(ToolKey.Archive);

  if (statusId === CampaignStatus.Draft) {
    updateTools.push(ToolKey.Delete);
  }
  return updateTools;
}

export function improve(campaign: Campaign): ImprovedCampaign {
  const ic = cloneDeep(campaign) as ImprovedCampaign;

  ic.cSmsFailed = ic.cSmsFailed || 0;
  ic.cSmsRestrictedContent = ic.cSmsRestrictedContent || 0;
  ic.cSmsStopList = ic.cSmsStopList || 0;
  ic.cSmsFailedUnroutable = ic.cSmsFailedUnroutable || 0;
  ic.cSmsFailedInvalidNumber = ic.cSmsFailedInvalidNumber || 0;
  ic.cSmsFailedFiltered = ic.cSmsFailedFiltered || 0;
  ic.cSmsDsErrors = ic.cSmsDsErrors || 0;
  ic.cSmsDsFiltered = ic.cSmsDsFiltered || 0;

  ic.smsFailed = ic.smsFailed || 0;
  ic.smsRestrictedContent = ic.smsRestrictedContent || 0;
  ic.smsStopList = ic.smsStopList || 0;
  ic.smsFailedUnroutable = ic.smsFailedUnroutable || 0;
  ic.smsFailedInvalidNumber = ic.smsFailedInvalidNumber || 0;
  ic.smsFailedFiltered = ic.smsFailedFiltered || 0;
  ic.smsDsErrors = ic.smsDsErrors || 0;
  ic.smsDsFiltered = ic.smsDsFiltered || 0;

  ic.cMessagesFailedTotal = ic.cMessagesFailedTotal || 0;
  ic.cMessagesRestrictedContent = ic.cMessagesRestrictedContent || 0;
  ic.cMessagesStopList = ic.cMessagesStopList || 0;
  ic.cMessagesFailedUnroutable = ic.cMessagesFailedUnroutable || 0;
  ic.cMessagesFailedInvalidNumber = ic.cMessagesFailedInvalidNumber || 0;
  ic.cMessagesFailedFiltered = ic.cMessagesFailedFiltered || 0;
  ic.cDsErrors = ic.cDsErrors || 0;
  ic.cDsFiltered = ic.cDsFiltered || 0;

  ic.messagesFailedTotal = ic.messagesFailedTotal || 0;
  ic.messagesRestrictedContent = ic.messagesRestrictedContent || 0;
  ic.messagesStopList = ic.messagesStopList || 0;
  ic.messagesFailedUnroutable = ic.messagesFailedUnroutable || 0;
  ic.messagesFailedInvalidNumber = ic.messagesFailedInvalidNumber || 0;
  ic.messagesFailedFiltered = ic.messagesFailedFiltered || 0;
  ic.dsErrors = ic.dsErrors || 0;
  ic.dsFiltered = ic.dsFiltered || 0;

  ic.messagesSentTotal = ic.messagesSentTotal || 0;
  ic.messagesTotal = ic.messagesTotal || 0;
  ic.cMessagesTotal = ic.cMessagesTotal || 0;
  ic.cMessagesSentTotal = ic.cMessagesSentTotal || 0;
  ic.dsDelivered = ic.dsDelivered || 0;
  ic.cDsDelivered = ic.cDsDelivered || 0;
  ic.smsDsDelivered = ic.smsDsDelivered || 0;
  ic.smsSent = ic.smsSent || 0;
  ic.cSmsDsDelivered = ic.cSmsDsDelivered || 0;
  ic.cSmsSent = ic.cSmsSent || 0;
  ic.smsTotal = ic.smsTotal || 0;
  ic.cSmsTotal = ic.cSmsTotal || 0;

  ic.cSmsErrorCount = ic.cSmsFailed + ic.cSmsRestrictedContent + ic.cSmsStopList
      + ic.cSmsFailedUnroutable + ic.cSmsFailedInvalidNumber + ic.cSmsFailedFiltered;
  ic.cSmsErrorCount += ic.dlrBilling ? ic.cSmsDsErrors + ic.cSmsDsFiltered : 0;

  ic.smsErrorCount = ic.smsFailed + ic.smsRestrictedContent + ic.smsStopList
      + ic.smsFailedUnroutable + ic.smsFailedInvalidNumber + ic.smsFailedFiltered;
  ic.smsErrorCount += ic.dlrBilling ? ic.smsDsErrors + ic.smsDsFiltered : 0;

  ic.cErrorCount = ic.cMessagesFailedTotal + ic.cMessagesRestrictedContent + ic.cMessagesStopList
      + ic.cMessagesFailedUnroutable + ic.cMessagesFailedInvalidNumber + ic.cMessagesFailedFiltered;
  ic.cErrorCount += ic.dlrBilling ? ic.cDsErrors + ic.cDsFiltered : 0;

  ic.errorCount = ic.messagesFailedTotal + ic.messagesRestrictedContent + ic.messagesStopList
      + ic.messagesFailedUnroutable + ic.messagesFailedInvalidNumber + ic.messagesFailedFiltered;
  ic.errorCount += ic.dlrBilling ? ic.dsErrors + ic.dsFiltered : 0;

  ic.filteredCount = ic.dlrBilling ? ic.cMessagesFailedFiltered + ic.cDsFiltered : ic.cMessagesFailedFiltered;

  ic.sentPct = ic.messagesTotal ? (100 * ic.messagesSentTotal) / ic.messagesTotal : 0;
  ic.errorPct = ic.messagesTotal ? (100 * ic.errorCount) / ic.messagesTotal : 0;
  ic.deliveredPct = ic.messagesSentTotal ? (100 * ic.dsDelivered) / ic.messagesSentTotal : 0;
  let totalForFiltered = ic.filteredCount + ic.messagesSentTotal
      + ic.messagesFailedInvalidNumber + ic.messagesFailedTotal;
  totalForFiltered += ic.dlrBilling ? ic.cDsErrors : 0;
  ic.filteredPct = totalForFiltered ? (100 * ic.filteredCount) / totalForFiltered : 0;
  ic.successCount = ic.dlrBilling ? ic.dsDelivered : ic.messagesSentTotal;
  ic.cSuccessCount = ic.dlrBilling ? ic.cDsDelivered : ic.cMessagesSentTotal;
  ic.smsSuccessCount = ic.dlrBilling ? ic.smsDsDelivered : ic.smsSent;
  ic.cSmsSuccessCount = ic.dlrBilling ? ic.cSmsDsDelivered : ic.cSmsSent;
  ic.ssSuccessCount = ic.successCount - ic.cSuccessCount;
  ic.ssSmsSuccessCount = ic.smsSuccessCount - ic.cSmsSuccessCount;
  ic.ssMessagesTotal = ic.messagesTotal - ic.cMessagesTotal;
  ic.ssSmsTotal = ic.smsTotal - ic.cSmsTotal;
  ic.ssErrorCount = ic.errorCount - ic.cErrorCount;
  ic.ssSmsErrorCount = ic.smsErrorCount - ic.cSmsErrorCount;
  return ic;
}

export function getExportStatuses(dlrBilling: boolean) {
    const result: any[] = [];
    return result.concat(
        [
            {
                k: null,
                v: s('All'),
                filter: null,
            },
            {
                k: MESSAGE_STATUS_NEW,
                v: s('New'),
                filter: {
                    $and: [{ message_status: MESSAGE_STATUS_NEW }],
                },
            },
        ],
        dlrBilling
            ? [
                {
                    k: MESSAGE_STATUS_SENT,
                    v: s('Delivered'),
                    filter: {
                        $and: [
                            { delivery_status: DELIVERY_DELIVRD },
                        ],
                    },
                },
            ]
            : [
                {
                    k: MESSAGE_STATUS_SENT,
                    v: s('Sent'),
                    filter: { $and: [{ message_status: MESSAGE_STATUS_SENT }] },
                },
            ],
        dlrBilling
            ? [
                {
                    k: MESSAGE_STATUS_FAILED,
                    v: s('Failed'),
                    filter: {
                        $or: [
                            {
                                message_status: {
                                    $in: [
                                        MESSAGE_STATUS_FAILED,
                                        MESSAGE_STATUS_SKIPPED,
                                        MESSAGE_STATUS_EXPIRED,
                                    ],
                                },
                            },
                            {
                                delivery_status: {
                                    $in: [
                                        DELIVERY_ENROUTE,
                                        DELIVERY_EXPIRED,
                                        DELIVERY_DELETED,
                                        DELIVERY_UNDELIV,
                                        DELIVERY_ACCEPTD,
                                        DELIVERY_UNKNOWN,
                                        DELIVERY_REJECTD,
                                        DELIVERY_SKIPPED,
                                    ],
                                },
                            },
                        ],
                    },
                },
            ]
            : [
                {
                    k: MESSAGE_STATUS_FAILED,
                    v: s('Failed'),
                    filter: {
                        $or: [
                            {
                                message_status: {
                                    $in: [
                                        MESSAGE_STATUS_FAILED,
                                        MESSAGE_STATUS_SKIPPED,
                                        MESSAGE_STATUS_EXPIRED,
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        [
            {
                k: MESSAGE_STATUS_UNROUTABLE,
                v: s('Unroutable'),
                filter: { $and: [{ message_status: MESSAGE_STATUS_UNROUTABLE }] },
            },
            {
                k: MESSAGE_STATUS_INVALID_NUMBER,
                v: s('Invalid number'),
                filter: { $and: [{ message_status: MESSAGE_STATUS_INVALID_NUMBER }] },
            },
            {
                k: MESSAGE_STATUS_EXPIRED,
                v: s('Expired'),
                filter: { $and: [{ message_status: MESSAGE_STATUS_EXPIRED }] },
            },
            {
                k: MESSAGE_STATUS_FILTERED,
                v: s('Filtered'),
                filter: {
                    $or: [
                        { message_status: MESSAGE_STATUS_FILTERED },
                        { delivery_status: DELIVERY_FILTERD },
                    ],
                },
            },
        ],
    );
}

export const STATE_IN_PROGRESS = 0;
export const STATE_COMPLETED = 1;
export const STATE_EXPORTED = 2;
export const STATE_WARNING = 3;
export const STATE_ABANDONED = 4;
export const STATE_ERROR = 5;
export const STATE_TAKEN_OVER = 6;
export const STATE_OPTED_OUT = 7;
export const STATE_DISCARDED = 8;
export const STATE_TIMED_OUT = 9;

export const STATE_TITLES = {
    [STATE_IN_PROGRESS]: 'In progress',
    [STATE_COMPLETED]: 'Completed',
    [STATE_EXPORTED]: 'Exported',
    [STATE_WARNING]: 'Warning',
    [STATE_ABANDONED]: 'Abandoned',
    [STATE_ERROR]: 'Error',
    [STATE_TAKEN_OVER]: 'Taken over',
    [STATE_OPTED_OUT]: 'Opted out',
    [STATE_DISCARDED]: 'Discarded',
    [STATE_TIMED_OUT]: 'Timed out',
};

export const stateIcons = {
    [STATE_IN_PROGRESS]: 'icon-in-progress text-primary font-larger',
    [STATE_COMPLETED]: 'icon-checkmark-stroke text-primary font-smaller',
    [STATE_EXPORTED]: 'icon-checkmark-stroke text-primary-muted font-smaller',
    [STATE_WARNING]: 'icon-warning text-warning font-larger',
    [STATE_ABANDONED]: 'icon-abandoned font-larger',
    [STATE_TAKEN_OVER]: 'icon-taken-over text-danger',
    [STATE_ERROR]: 'icon-need-attention text-danger',
    [STATE_OPTED_OUT]: 'icon-close text-danger font-larger',
    [STATE_DISCARDED]: 'icon-delete',
    [STATE_TIMED_OUT]: 'icon-close text-muted-darker font-larger',
};

export const NEEDING_ATTENTION_STATES = [
  STATE_ABANDONED,
  STATE_ERROR,
  STATE_COMPLETED,
  STATE_TAKEN_OVER,
];
