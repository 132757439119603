import {
  ApiQueryParams, ApiQueryResult, DataGrid,
  useGridRef, GridColumn,
} from '@xeebi/neru';
import { fetcher } from 'graphql-api/fetcher';
import {
  useCallback, useEffect, useMemo,
} from 'react';
import { Stack } from '@mui/material';
import muiStruct from 'shared-scope/helpers/muiStruct';
import { AxiosRequestConfig } from 'axios';
import { omit } from 'lodash';
import useStatisticForm from 'products/Account/Tabs/Statistics/Billing/useStatisticBillingForm';
import BillingStruct from 'products/Account/Tabs/Statistics/Billing/Struct';
import { getSdk } from 'products/Account/Tabs/Statistics/queries.generated';
import { Export } from 'products/Account/Tabs/Statistics/components';
import { ColumnGroup, StatisticColumn } from 'products/Account/Tabs/Statistics/types';
import { DATE_FORMAT, getParams } from 'products/Account/Tabs/Statistics/helpers';


const api = getSdk(fetcher);

const GRID_STORAGE_ID = 'StatisticsBillingGridStorage';

export default function BillingView() {
  const gridRef = useGridRef();

  const {
    statFormRender,
    viewParams,
    statFormRow,
  } = useStatisticForm(BillingStruct, DATE_FORMAT);

  const loadStatistics = useCallback(async (params: ApiQueryParams, options: AxiosRequestConfig) => {
    const {
      billingStat,
      billingStatCount,
      billingStatTotal,
    } = await api.getBillingStat({
      ...params,
      ...omit(viewParams, 'dataColumns'),
    }, options);
    return {
      count: billingStatCount,
      rows: billingStat,
      total: billingStatTotal,
    } as ApiQueryResult;
  }, [viewParams]);

  const exportParams = useCallback(() => getParams(viewParams, gridRef), [viewParams, gridRef]);

  const gridColumns = useMemo(
    () => {
      const getStruct = (cols: StatisticColumn[] = []) => {
        const colsFields = cols.map((c) => c.field);
        return BillingStruct().filter((c) => colsFields.includes(c.field));
      };

      return [
        ...BillingStruct(ColumnGroup.common),
        ...getStruct(statFormRow.generalColumns),
        ...getStruct(statFormRow.billingCountersColumns),
      ];
    },
    [statFormRow.generalColumns, statFormRow.billingCountersColumns],
  );

  /**
   * Refresh grid
   */
  useEffect(() => {
    gridRef.current.refresh();
  }, [gridColumns, loadStatistics, gridRef]);

  return (
    <Stack spacing={2}>
      {statFormRender}
      <DataGrid
        storageId={GRID_STORAGE_ID}
        pageSize={10}
        columns={muiStruct(gridColumns as GridColumn[])}
        getRows={loadStatistics}
        apiRef={gridRef}
        customTools={[
          <Export key="export" params={exportParams} type="billing" />,
        ]}
        onError={() => {}}
        nullRender="-"
      />
    </Stack>
  );
}
