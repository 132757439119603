import { StatisticView } from 'products/Account/Tabs/Statistics/types';
import { Box, Grid, IconButton } from '@mui/material';
import Icon from '@mui/material/Icon';
import React from 'react';
import { MessageStatConfig } from 'graphql-api';
import Loading from 'shared-scope/components/Loading';

export default function SingleView({
 view, loading, onDelete, onEdit, onClick,
}: SingleViewProps) {
  const config = JSON.parse(view.config || '') as StatisticView;
  return <Box
    sx={{
      color: config.color,
      borderRadius: '4px',
      padding: '8px',
      width: '100%',
      '& .actions': {
        display: 'none',
      },
      '&:hover': {
        border: `1px solid ${config.color}`,
        padding: (onDelete || onEdit) && !loading ? '1px 7px' : '7px',
        cursor: 'pointer',
        '& .actions': {
          display: 'block',
        },
      },
    }}
  >
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      columnSpacing={3}
    >
      <Grid
        item
        xs
        sx={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
        title={view.name || ''}
        onClick={() => onClick?.()}
      >
        {view.name}
      </Grid>
      <Grid item xs="auto">
        {loading
          ? <Loading size={24} />
          : <Box className="actions">
            {onEdit && <IconButton
              onClick={() => onEdit?.()}
            >
              <Icon className="icon-edit" sx={{ fontSize: '20px', color: '#4C4C4C' }} />
            </IconButton>}
            {onDelete && <IconButton
              onClick={() => onDelete?.()}
            >
              <Icon className="icon-delete" sx={{ fontSize: '20px', color: '#CE0058' }} />
            </IconButton>}
          </Box>}
      </Grid>
    </Grid>
  </Box>;
}

type SingleViewProps = {
  view: MessageStatConfig
  loading?: boolean
  onDelete?: () => void
  onEdit?: () => void
  onClick?: () => void
  selected?: boolean
};
