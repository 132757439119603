import { s } from 'i18n';
import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import TagButton from 'shared-scope/components/TagButton';
import MessagesView from 'products/Account/Tabs/Statistics/Messages';
import BillingView from 'products/Account/Tabs/Statistics/Billing';

const MESSAGES = s('Messages');
const BILLING = s('Billing');

export default function Statistics() {
  const [tab, setTab] = useState(MESSAGES);

  return (
    <Box sx={{ p: 1 }}>
      <Stack spacing={1}>
        <Stack
          spacing={1}
          direction="row"
          flexWrap="wrap"
          useFlexGap
        >
          <TagButton
            onClick={() => setTab(MESSAGES)}
            isActive={tab === MESSAGES}
          >
            {MESSAGES}
          </TagButton>
          <TagButton
            onClick={() => setTab(BILLING)}
            isActive={tab === BILLING}
          >
            {BILLING}
          </TagButton>
        </Stack>

        {tab === MESSAGES
          ? <MessagesView />
          : null}

        {tab === BILLING
          ? <BillingView />
          : null}

      </Stack>
    </Box>
  );
}
