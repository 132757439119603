import { ControlledComponent, TZ } from 'shared-scope/types';
import getTimezones from 'shared-scope/helpers/getTimezones';
import { s } from 'i18n';
import { Combobox } from '@xeebi/neru';
import React from 'react';

const timeZones = getTimezones();

export default function TimezoneSelect({ value, onChange }: ControlledComponent<string>) {
  return (
    <Combobox<TZ>
      value={timeZones.find((tz) => tz.k === value) || null}
      onChange={(e, tz) => onChange(tz?.k || '')}
      options={timeZones}
      placeholder={s('Time zone')}
      title={s('Time zone')}
      optionKey="k"
      optionValue="v"
    />
  );
}
