import { ControlledComponent } from 'shared-scope/types';
import { Combobox, GridColumn } from '@xeebi/neru';
import { StatisticColumn } from 'products/Account/Tabs/Statistics/types';
import uniqBy from 'lodash/uniqBy';

export default function ColumnsSelect({
 value, onChange, options, title, required,
}: ColumnsSelectProps) {
  const groupCount = uniqBy(options, 'group').length;

  return (
    <Combobox<StatisticColumn, true, true>
      title={title}
      placeholder={title}
      options={options}
      groupBy={groupCount > 1 ? (option) => option.group || '' : undefined}
      value={value}
      onChange={(e, v) => v && onChange(uniqBy(v, 'field'))}
      optionKey="field"
      optionValue="headerName"
      multiple
      checkbox
      limitTags={1}
      required={required}
      disableClearable={required || undefined}
      disableCloseOnSelect
    />
  );
}

type ColumnsSelectProps = ControlledComponent<StatisticColumn[]> & {
  title: string
  options: GridColumn[]
  required?: boolean
};

