import * as momentTZ from 'moment-timezone';
import { TZ } from '../types';


let cacheTzNames: any = null;

export default function getTimezones(): TZ[] {
  const moment = momentTZ;
  if (!cacheTzNames) {
    cacheTzNames = moment.tz.names();
  }
  const list = cacheTzNames.map((tz: string) => ({
    k: tz,
    v: moment.tz(tz).format('Z') + ' - ' + tz,
    offset: moment.tz(tz).utcOffset(),
  })).filter((tz: any) => !tz.k.toLowerCase().startsWith('etc'));

  list.push({ k: 'PST', v: '-08:00 - PST', offset: 480 },
    { k: 'PDT', v: '-07:00 - PDT', offset: 420 },
    { k: 'CST', v: '-06:00 - CST', offset: 360 },
    { k: 'CDT', v: '-05:00 - CDT', offset: 300 });
  list.sort((a: { offset: number }, b: { offset: number }) => a.offset - b.offset);

  return list;
}
