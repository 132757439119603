import {
  Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Stack, TextField, Typography,
} from '@mui/material';
import { s } from 'i18n';
import FormButton, { FormButtonSecondary } from 'shared-scope/components/FormButton';
import { StatisticViewAvail, StatisticViewForm } from 'products/Account/Tabs/Statistics/types';
import { useCallback, useState } from 'react';
import ColoredRadioGroup from 'products/Account/Tabs/Statistics/components/ColoredRadioGroup';
import Checkbox from 'shared-scope/components/Checkbox';


export const COLORS = [
  '#354451',
  '#B8004F',
  '#DF7713',
  '#179633',
  '#0099A7',
  '#0F4EB5',
  '#6554C0',
];

export default function ViewSaveModal({
 title, view, open, onClose, onSave, onChangeView,
}: ViewSaveModalProps) {
  const [error, setError] = useState<ViewSaveModalError>({});

  const handleView = useCallback((v: StatisticViewForm) => {
    onChangeView({ ...view, ...v });
  }, [view, onChangeView]);

  const validate = useCallback(() => {
    const errors: ViewSaveModalError = {};
    if (!view?.title) {
      errors.title = s('Title can\'t be empty');
    }
    if (!view?.color) {
      errors.color = s('Choose a color for view');
    }
    if (!(view?.[StatisticViewAvail.sorting]
          || view?.[StatisticViewAvail.filter]
          || view?.[StatisticViewAvail.columns]
          || view?.[StatisticViewAvail.content])) {
      errors.component = s('Choose at least one component for view');
    }
    setError(errors);
    return !Object.keys(errors).length;
  }, [view]);

  return <Dialog
    disableEscapeKeyDown
    maxWidth="xs"
    PaperProps={{ className: 'form-responsive', sx: { minHeight: '250px' } }}
    open={open}
    onClose={onClose}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent dividers>
      <Stack spacing={2}>
        <TextField
          value={view?.title || ''}
          onChange={(e) => {
            handleView({ title: e.target.value });
          }}
          size="small"
          label={s('Title')}
          required
          error={!!error.title}
          helperText={error.title}
        />
        <ColoredRadioGroup
          title={s('Color')}
          colors={COLORS}
          value={view?.color || ''}
          onChange={(v) => handleView({ color: v })}
          error={error.color}
        />
        <Stack spacing={1}>
          <Typography variant="body1">{s('Components') + ' *'}</Typography>
          {error.component
            && <Typography variant="subtitle1" color={({ palette }) => palette.error.main}>{error.component}</Typography>}
          <FormGroup>
            <Checkbox
              value={!!view?.[StatisticViewAvail.sorting]}
              onChange={(e, v) => handleView({ [StatisticViewAvail.sorting]: v })}
              label={s('Sorting')}
              size="small"
            />
            <Checkbox
              value={!!view?.[StatisticViewAvail.filter]}
              onChange={(e, v) => handleView({ [StatisticViewAvail.filter]: v })}
              label={s('Filter')}
              size="small"
            />
            <Checkbox
              value={!!view?.[StatisticViewAvail.columns]}
              onChange={(e, v) => handleView({ [StatisticViewAvail.columns]: v })}
              label={s('Column size')}
              size="small"
            />
            <Checkbox
              value={!!view?.[StatisticViewAvail.content]}
              onChange={(e, v) => handleView({ [StatisticViewAvail.content]: v })}
              label={s('Content')}
              size="small"
            />
          </FormGroup>
        </Stack>
      </Stack>
    </DialogContent>
    <DialogActions>
      <FormButtonSecondary
        className="align-self-sm-start"
        onClick={() => onClose()}
      >
        {s('Cancel')}
      </FormButtonSecondary>
      <FormButton
        disabled={false}
        onClick={() => validate() && onSave()}
      >
        {s('Save view')}
      </FormButton>
    </DialogActions>
  </Dialog>;
}

type ViewSaveModalProps = {
  title: string
  open: boolean
  view: StatisticViewForm | null
  onChangeView: (v: StatisticViewForm) => void
  onClose: () => void
  onSave: () => void
};

type ViewSaveModalError = {
  title?: string
  color?: string
  component?: string
};
