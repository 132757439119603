import { s } from 'i18n';
import { Box, Stack } from '@mui/material';
import { ColumnGroup, StatisticColumn } from 'products/Account/Tabs/Statistics/types';

const WIDTH_NUMBER = 96;
const WIDTH_TEXT = 148;

export default function BillingStruct(groups: ColumnGroup | ColumnGroup[] = []) {
  const filterGroups = Array.isArray(groups) ? groups : [groups];

  const columns: StatisticColumn[] = [
    // {
    //   field: 'id',
    //   headerName: s('ID'),
    //   type: 'string',
    //   width: WIDTH_NUMBER,
    //   group: ColumnGroup.general,
    // },
    {
      field: 'ts',
      headerName: s('Date'),
      type: 'date',
      width: WIDTH_TEXT,
      group: ColumnGroup.common,
      renderCell: (params) => {
        const value = params.value || '-';
        const dates = (value as string).split(' - ');
        return dates.length > 1 ? (
          <Stack spacing={0} direction="column">
            <Box>{dates[0]} -</Box>
            <Box>{dates[1]}</Box>
          </Stack>
        ) : value;
      },
    },
    {
      field: 'trxType',
      headerName: s('Type'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.general,
    },
    {
      field: 'module',
      headerName: s('Module'),
      type: 'string',
      group: ColumnGroup.general,
    },
    {
      field: 'accountIncome',
      headerName: s('Account Income'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.billingCounters,
    },
    {
      field: 'accountExpense',
      headerName: s('Account Expense'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.billingCounters,
    },
    {
      field: 'bonusIncome',
      headerName: s('Bonus Income'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.billingCounters,
    },
    {
      field: 'bonusExpense',
      headerName: s('Bonus Expense'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.billingCounters,
    },
    {
      field: 'accountTotal',
      headerName: s('Account Total'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.billingCounters,
    },
  ];

  return filterGroups.length
    ? columns.filter(
      (col) => (col.group ? filterGroups.includes(col.group) : false),
    ) : columns;
}
