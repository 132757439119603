import { s } from 'i18n';
import { ReactNode } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-pro';
import { Number } from '@xeebi/neru';
import { isNil } from 'lodash';
import { ColumnGroup, StatisticColumn } from 'products/Account/Tabs/Statistics/types';

const WIDTH_NUMBER = 96;
const WIDTH_TEXT = 148;
export default function Struct(groups: ColumnGroup | ColumnGroup[] = []) {
  const filterGroups = Array.isArray(groups) ? groups : [groups];

  const columns: StatisticColumn[] = [
    // {
    //   field: 'id',
    //   headerName: s('ID'),
    //   type: 'string',
    //   width: WIDTH_NUMBER,
    //   group: ColumnGroup.general,
    // },
    {
      field: 'ts',
      headerName: s('Date'),
      type: 'date',
      width: WIDTH_TEXT,
      group: ColumnGroup.common,
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => {
        const value = params.value || '-';
        const dates = (value as string).split(' - ');
        return dates.length > 1 ? (
          <Stack spacing={0} direction="column">
            <Box>{dates[0]} -</Box>
            <Box>{dates[1]}</Box>
          </Stack>
        ) : value;
      },
    },
    {
      field: 'campaign',
      headerName: s('Campaign'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.general,
    },
    {
      field: 'country',
      headerName: s('Country'),
      type: 'string',
      group: ColumnGroup.general,
    },
    {
      field: 'operator',
      headerName: s('Operator'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.general,
    },
    {
      field: 'route',
      headerName: s('Route'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.general,
    },
    {
      field: 'template',
      headerName: s('Template'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.general,
    },
    {
      field: 'shortlinkDomain',
      headerName: s('Domain'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.general,
    },
    {
      field: 'geoCountry',
      headerName: s('Geo Country'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.geo,
    },
    {
      field: 'geoState',
      headerName: s('Geo State'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.geo,
    },
    {
      field: 'geoCity',
      headerName: s('Geo City'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.geo,
    },
    {
      field: 'ispName',
      headerName: s('Internet service provider'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.technologies,
    },
    {
      field: 'deviceType',
      headerName: s('Device'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.technologies,
    },
    {
      field: 'osName',
      headerName: s('Operating system'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.technologies,
    },
    {
      field: 'browserName',
      headerName: s('Browser'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.technologies,
    },
    {
      field: 'ipAddress',
      headerName: s('IP address'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.technologies,
    },
    {
      field: 'ipNetwork',
      headerName: s('IP network'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.technologies,
    },
    {
      field: 'userAgent',
      headerName: s('User agent'),
      type: 'string',
      width: WIDTH_TEXT,
      group: ColumnGroup.technologies,
    },
    {
      field: 'sentCount',
      headerName: s('Messages'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.successMessages,
    },
    {
      field: 'clickCount',
      headerName: s('Clicks'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.successMessages,
    },
    {
      field: 'clickedCount',
      headerName: s('Clicked'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.successMessages,
      depsColumns: ['clickedRate'],
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => renderRate(params, 'clickedRate'),
    },
    {
      field: 'repliedCount',
      headerName: s('Replied'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.successMessages,
    },
    {
      field: 'deliveredCount',
      headerName: s('Delivered'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.successMessages,
      depsColumns: ['deliveryRate'],
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => renderRate(params, 'deliveryRate'),
    },
    {
      field: 'filteredCount',
      headerName: s('Filtered'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.errorMessages,
      depsColumns: ['filteredRate'],
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => renderRate(params, 'filteredRate'),
    },
    {
      field: 'errorCount',
      headerName: s('Errors'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.errorMessages,
      depsColumns: ['errorRate'],
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => renderRate(params, 'errorRate'),
    },
    {
      field: 'failedCount',
      headerName: s('Failed'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.errorMessages,
    },
    {
      field: 'stopListCount',
      headerName: s('Blocked by opt-out list'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.errorMessages,
    },
    {
      field: 'restrictedContentCount',
      headerName: s('Restricted'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.errorMessages,
    },
    {
      field: 'invalidNumberCount',
      headerName: s('Invalid number'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.errorMessages,
    },
    {
      field: 'unroutableCount',
      headerName: s('Unroutable'),
      type: 'number',
      width: WIDTH_NUMBER,
      group: ColumnGroup.errorMessages,
    },
    {
      field: 'smsAmount',
      headerName: s('SMS fee'),
      type: 'string',
      width: WIDTH_NUMBER,
      group: ColumnGroup.billing,
    },
    {
      field: 'moAmount',
      headerName: s('Mobile originated fee'),
      type: 'string',
      width: WIDTH_NUMBER,
      group: ColumnGroup.billing,
    },
    {
      field: 'mtAmount',
      headerName: s('Mobile terminated fee'),
      type: 'string',
      width: WIDTH_NUMBER,
      group: ColumnGroup.billing,
    },
    {
      field: 'atchAmount',
      headerName: s('Attachment fee'),
      type: 'string',
      width: WIDTH_NUMBER,
      group: ColumnGroup.billing,
    },
    {
      field: 'ptfAmount',
      headerName: s('Platform fee'),
      type: 'string',
      width: WIDTH_NUMBER,
      group: ColumnGroup.billing,
    },
    {
      field: 'totalAmount',
      headerName: s('Total fee'),
      type: 'string',
      width: WIDTH_NUMBER,
      group: ColumnGroup.billing,
    },
  ];

  return filterGroups.length
    ? columns.filter(
      (col) => (col.group ? filterGroups.includes(col.group) : false),
    ) : columns;
}

const renderRate = (params: GridRenderCellParams, rateField: string, countField?: string): ReactNode => {
  const row = params.id && params.api.getRow(params.id);

  const count = countField && row ? row[countField] : params.value;
  const rate = row?.[rateField];

  const countRender = count !== null ? <Number value={count} /> : '';

  return rate !== null
    ? <Stack spacing={0} alignItems="flex-end">
      <Typography variant="h4">
        {!isNil(rate) ? `${rate}%` : ''}
      </Typography>
      <Typography variant="subtitle1">
        {countRender}
      </Typography>
    </Stack>
    : countRender;
};
