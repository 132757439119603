import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { s } from 'i18n';
import { ControlledComponent } from 'shared-scope/types';
import { Period } from '../types';

export default function PeriodToggle({ value, onChange }: ControlledComponent<Period>) {
  return (
    <ToggleButtonGroup
      value={value}
      onChange={(e, v) => onChange(v)}
      exclusive
      aria-label="interval-toggle"
      sx={{
        '& .MuiToggleButton-root': {
          padding: '4px',
          fontWeight: 400,
        },
      }}
    >
      <ToggleButton value={Period.hour}>{s('Hour')}</ToggleButton>
      <ToggleButton value={Period.day}>{s('Day')}</ToggleButton>
      <ToggleButton value={Period.week}>{s('Week')}</ToggleButton>
      <ToggleButton value={Period.month}>{s('Month')}</ToggleButton>
    </ToggleButtonGroup>
  );
}
