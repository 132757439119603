import axios from 'axios';
import * as CONFIG from 'shared-scope/config';

function getEffectiveUser() {
  let EUID = null;
  let CID = null;
  const re = new RegExp(`^\\/([0-9]+\\/*)?${process.env.REACT_APP_BASE_PATH}\\/?([0-9]+)?`);
  const m = window.location.pathname.match(re);

  if (m && m[1]) CID = parseInt(m[1], 10);
  if (m && m[2]) EUID = parseInt(m[2], 10);
  return [CID, EUID];
}

function getBasePath() {
  const [CID, EUID] = getEffectiveUser();
  let BASENAME = `${CID ? `/${CID}` : ''}` + PUBLIC_PATH.replace(/(\/login)?\/$/, '/') + `${EUID ? `${EUID}/` : ''}`;
  BASENAME = BASENAME.replace(/\/$/, '');
  return BASENAME;
}

/**
 * Get config
 */
export default async function init() {
  const [CID, EUID] = getEffectiveUser();
  const basePath = getBasePath();
  window.BASENAME = basePath;

  const configPath = `${basePath}${process.env.REACT_APP_CONFIG_PATH || '/config.json'}?${Math.random()}`;

  console.log('path to config file: ', configPath);
  const { data } = await axios.get(configPath);
  if (!data) {
    throw new Error('Cannot find config.json');
  } else {
    CONFIG.save({
      ...data,
      EUID,
      CID,
    });
  }
}
