import {
  Button, Stack, Typography,
} from '@mui/material';
import { Maybe, MessageStatConfig } from 'graphql-api';
import React from 'react';
import { StatisticView } from '../types';

export default function ViewList({ views, selected, onClick }: ViewListProps) {
  return (
    <Stack
      direction="row"
      spacing={{ xs: 0, md: 1 }}
      sx={{
        maxWidth: '100%',
        overflow: 'hidden',
      }}
    >
      {views.map((v) => (
        <SingleView
          view={v}
          onClick={() => onClick(v)}
          selected={v.id === selected?.id}
          key={v.id}
        />
      ))}
    </Stack>
  );
}

function SingleView({ view, onClick }: SingleViewProps) {
  const config = JSON.parse(view.config || '') as StatisticView;
  return <Button
    variant="text"
    sx={{
      borderRadius: '100px',
      padding: '4px 10px',
      textTransform: 'none',
      maxWidth: '120px',
      minWidth: '60px !important',
      textAlign: 'left',
    }}
    title={view.name || ''}
    onClick={() => onClick?.()}
  >
    <Typography
      variant="h5"
      color={config.color}
      sx={{
        maxWidth: '150px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >{view.name}</Typography>
  </Button>;
}

type ViewListProps = {
  views: MessageStatConfig[]
  selected: Maybe<MessageStatConfig>
  onClick: (v: Maybe<MessageStatConfig>) => void
};

type SingleViewProps = {
  view: MessageStatConfig
  onClick?: () => void
  selected?: boolean
};
