import { GridColDef } from '@mui/x-data-grid-pro';
import { GridColumn } from '@xeebi/neru';

export default function muiStruct(struct: Partial<GridColDef>[]): GridColumn[] {
  const customDefaults = {
    disableColumnMenu: true,
    flex: 0,
  };

  return struct.map((col) => ({ ...customDefaults, ...col })) as GridColumn[];
}
