import {
  CircularProgress, Button, ExtendButtonBase, ButtonTypeMap,
} from '@mui/material';
// import { Row } from '@xeebi/neru';
import { styled } from '@mui/material/styles';
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette';
import { grey } from '@mui/material/colors';
import Icon from '@mui/material/Icon';

export const white = '#ffffff';

const FormButtonIn = styled(Button, {
  name: 'MuiButton', // it's to find in theme object to
  shouldForwardProp: () => true,
})((params) => ({
  borderRadius: '0.94em',
  textTransform: 'none',
  // eslint-disable-next-line no-nested-ternary
  padding: !params.size || params.size === 'medium' ? '5px 17px'
    : params.size === 'small' ? '1px 14px' : undefined,
  fontWeight: 500,
  lineHeight: '24px',
  boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.25)',
  minWidth: '4.4rem', // save place for spinner
})) as ExtendButtonBase<ButtonTypeMap>; // seems React bug here, type casting is used for component={RouterLink} passing to the component

function FormButton({ loading, children, ...other }: any) {
  // console.log('FormButtonIn: other=', other, children);
  return (
    <FormButtonIn disabled={loading}
      variant={loading ? 'outlined' : 'contained'}
      {...other}
    >{loading
      ? <><CircularProgress size={25} color="primary" style={loading ? { position: 'absolute' } : {}} /><span className="opacity-50">{children}</span></>
      : children}
    </FormButtonIn>
  );
}

const FormButtonSecondary = styled(FormButtonIn, {
  name: 'MuiButtonSecondary', // it's to find custom variants in theme object
  shouldForwardProp: () => true,
  // specifying here how the styleOverrides are being applied based on props
  // overridesResolver: (_, styles) => [
  //   styles.root, // default styles
  // ],
})(() => {
  // window.console.log('BS: params = ', props);
  // const variants = theme?.components?.MuiButton?.variants || [];
  /* const cmp: Row = props.theme?.components || {};
  const styleOverrides = cmp.MuiButtonSecondary?.styleOverrides || {};

  const variants = cmp.MuiButtonSecondary?.variants || [];
  const found = variants.find((v: any) => v && v.props.color === 'secondary');

  return found ? found.style : styleOverrides; */
  return {};
}) as ExtendButtonBase<ButtonTypeMap>;

const FormButtonMajor = styled(FormButtonIn, {
  name: 'MuiButtonMajor',
  shouldForwardProp: () => true,
})((params) => {
  const btn = params.theme.palette.buttonMajor as SimplePaletteColorOptions;
  return {
    background: `linear-gradient(180deg, ${btn.light}, ${btn.main}) ${btn.main}`,
    padding: '5px 2.5rem',
    '&:hover': {
      background: btn.light,
      textDecorationColor: grey[50], // i.e. white
      color: white,
    },
    '&.Mui-disabled': {
      background: grey[300],
    },
  };
}) as ExtendButtonBase<ButtonTypeMap>; // it's to translate component={RouterLink} to the component

function BackIcon() {
  return <i className="icon-back" style={{ margin: '0 -6px 2px -2px' }} />;
}

const TdButton = styled(FormButton, {
  name: 'MuiToolbarButtonSecondary', // it's to find in theme object to
  shouldForwardProp: () => true,
})(() => {
  return {
    padding: '2px 2px',
    minWidth: '2.0rem',
    fontSize: '1rem',
    lineHeight: '20px',
  };
}) as ExtendButtonBase<ButtonTypeMap>;

const IconBtnCircle = styled(Icon, {
  name: 'MuiIconBtnCircle', // it's to find in theme object to
  shouldForwardProp: () => true,
  // specifying here how the styleOverrides are being applied based on props
  overridesResolver: (props, styles) => [
    styles.root, // default styles
  ],
})(() => {
  return {
    width: '1.2em',
    height: '1.2em',
    padding: '0.1em',
    borderRadius: '1.2em',
    cursor: 'pointer',
    '&[disabled]': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)',
      pointerEvents: 'none',
      cursor: 'default',
    },
  };
}) as ExtendButtonBase<ButtonTypeMap>;

export default FormButton;
export {
 FormButtonMajor, FormButtonSecondary, BackIcon, TdButton, IconBtnCircle,
};
