import {
  DateRangePicker, DateRangeValidationError, LocalizationProvider,
  PickerChangeHandlerContext, PickersShortcutsItem, SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ControlledComponent } from 'shared-scope/types';
import { s } from 'i18n';
import {
 DateRangeDate, DateRangeValue, Shortcut, ShortcutValue,
} from 'products/Account/Tabs/Statistics/types';
import { useCallback, useMemo } from 'react';
import { invert } from 'lodash';

export default function IntervalRange({ value, onChange }: ControlledComponent<DateRangeValue>) {
  const handleChange = useCallback((
    newValue: DateRangeDate,
    ctx: PickerChangeHandlerContext<DateRangeValidationError>,
  ) => {
    const val = ctx.shortcut?.label ? shortcutValues[ctx.shortcut.label as Shortcut] : newValue;
    onChange(val);
  }, [onChange]);

  const dateValue = useMemo(() => {
    let val: DateRangeDate;
    if (Array.isArray(value)) {
      if (typeof value[0] === 'string' || typeof value[1] === 'string') {
        val = [dayjs(value[0]), dayjs(value[1])];
      } else {
        val = value as DateRangeDate;
      }
    } else {
      const shortcuts = invert(shortcutValues) as Record<ShortcutValue, Shortcut>;
      const shortcut = shortcuts[value];
      const shortcutItem = shortcutsItems.find(
        (sc) => sc.label === shortcut,
      );
      val = shortcutItem ? shortcutItem.getValue({ isValid: () => true }) : [null, null];
    }
    return val;
  }, [value]);

  const dateLabel = useMemo(() => {
    let shortcut;
    if (!Array.isArray(value)) {
      const shortcuts = invert(shortcutValues) as Record<ShortcutValue, Shortcut>;
      shortcut = shortcuts[value];
    }
    return s('Period') + (shortcut ? ` - ${shortcut}` : '');
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        label={dateLabel}
        value={dateValue}
        onChange={handleChange}
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
            changeImportance: 'set',
          },
        }}
        disableFuture
      />
    </LocalizationProvider>
  );
}

const shortcutsItems: PickersShortcutsItem<DateRangeDate>[] = [
  {
    label: Shortcut.h24,
    getValue: () => {
      const today = dayjs();
      return [today.subtract(24, 'hours'), today];
    },
  },
  {
    label: Shortcut.d3,
    getValue: () => {
      const today = dayjs();
      return [today.subtract(3, 'days'), today];
    },
  },
  {
    label: Shortcut.d7,
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: Shortcut.thisW,
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today];
    },
  },
  {
    label: Shortcut.prevW,
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  {
    label: Shortcut.lastM,
    getValue: () => {
      const today = dayjs();
      return [today.subtract(1, 'month'), today];
    },
  },
  {
    label: Shortcut.thisM,
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today];
    },
  },
  {
    label: Shortcut.prevM,
    getValue: () => {
      const today = dayjs();
      const startOfPrevMonth = today.subtract(1, 'month').startOf('month');
      return [startOfPrevMonth, startOfPrevMonth.endOf('month')];
    },
  },
  {
    label: Shortcut.thisY,
    getValue: () => {
      const today = dayjs();
      return [today.startOf('year'), today];
    },
  },
  {
    label: Shortcut.prevY,
    getValue: () => {
      const today = dayjs();
      const startOfPrevYear = today.subtract(1, 'year').startOf('year');
      return [startOfPrevYear, startOfPrevYear.endOf('year')];
    },
  },
];

const shortcutValues: Record<Shortcut, ShortcutValue> = {
  [Shortcut.h24]: ShortcutValue.h24,
  [Shortcut.d3]: ShortcutValue.d3,
  [Shortcut.d7]: ShortcutValue.d7,
  [Shortcut.thisW]: ShortcutValue.thisW,
  [Shortcut.prevW]: ShortcutValue.prevW,
  [Shortcut.lastM]: ShortcutValue.lastM,
  [Shortcut.thisM]: ShortcutValue.thisM,
  [Shortcut.prevM]: ShortcutValue.prevM,
  [Shortcut.thisY]: ShortcutValue.thisY,
  [Shortcut.prevY]: ShortcutValue.prevY,
};
