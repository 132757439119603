import React, { MutableRefObject } from 'react';
import { useOutletContext } from 'react-router-dom';
import { DateRangeDate, StatisticColumn } from 'products/Account/Tabs/Statistics/types';
import dayjs from 'dayjs';
import { GridRefType, SortItem } from '@xeebi/neru';
import { forOwn } from 'lodash';


export const DATE_FORMAT = 'YYYY-MM-DD 00:00:00';

export const getFieldsList = (columns: StatisticColumn[] | undefined) => columns?.reduce(
  (group, col) => [...group, col.field, ...(col.depsColumns || [])], [] as string[],
) || [];

export const getColumns = (struct: StatisticColumn[], fields: string[] | undefined) => struct.filter((col) => fields?.includes(col.field) || false);

export const getIntervalDate = (interval: DateRangeDate | [string, string], dateFormat: string): null | string[] => {
  const start = typeof interval[0] === 'string' ? dayjs(interval[0]) : interval[0];
  const end = typeof interval[1] === 'string' ? dayjs(interval[1]) : interval[1];
  let result = null;

  if (start && end) {
    result = [
      start.format(dateFormat),
      end.add(1, 'day').format(dateFormat),
    ];
  } else if (start && !end) {
    result = [
      start.format(dateFormat),
      start.add(1, 'day').format(dateFormat),
    ];
  } else if (!start && end) {
    result = [
      end.format(dateFormat),
      end.add(1, 'day').format(dateFormat),
    ];
  }

  return result;
};

export function getParams(viewParams: any, gridRef: MutableRefObject<GridRefType>) {
  const sort: [string, 'asc' | 'desc'][] = [];
  gridRef.current?.mangoSort?.forEach((srt: SortItem) => {
    forOwn(srt, (v, k) => sort.push([k, v === 1 ? 'asc' : 'desc']));
  });

  return {
    ...viewParams,
    filter: JSON.stringify(gridRef.current.mangoFilter),
    sort: JSON.stringify(sort),
  };
}

export function useStatProps() {
  return useOutletContext<StatisticProps>();
}

export type StatisticProps = {
  onError: React.Dispatch<React.SetStateAction<string | false>>
};
