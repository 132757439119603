import { FormControlLabel, Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from '@mui/material';

export default function Checkbox({
 value, label, ...props
}: CheckboxProps) {
  return <FormControlLabel
    control={<MuiCheckbox
      checked={value}
      {...props}
    />}
    label={label}
  />;
}

type CheckboxProps = MuiCheckboxProps & {
  label?: string
  value: boolean
};
