import { GridFilterInitialState, GridSortingInitialState } from '@mui/x-data-grid-pro';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { GridColumn } from '@xeebi/neru';
import type { GridColumnsInitialState } from '@mui/x-data-grid/hooks';

export enum Period {
  hour = 'hour',
  day = 'day',
  week = 'week',
  month = 'month',
}

export enum ColumnGroup {
  common = 'Common',
  general = 'General',
  geo = 'Geo',
  technologies = 'Technologies',
  successMessages = 'Success messages',
  errorMessages = 'Error messages',
  billing = 'Billing data',
  billingCounters = 'Billing stat',
}

type Column = {
  group?: ColumnGroup
  depsColumns?: string[]
};
export type StatisticColumn = GridColumn & Column;

export type DateRangeDate = DateRange<Dayjs>;
export type DateRangeValue = DateRangeDate | [string, string] | ShortcutValue;

export enum Shortcut {
  h24 = '24 hours',
  d3 = 'Last 3 days',
  d7 = 'Last 7 days',
  thisW = 'This week',
  prevW = 'Prev. week',
  lastM = 'Last month',
  thisM = 'This month',
  prevM = 'Prev. month',
  thisY = 'This year',
  prevY = 'Prev. year',
}

export enum ShortcutValue {
  h24 = '24h',
  d3 = '3d',
  d7 = '7d',
  thisW = 'thisw',
  prevW = 'prevw',
  lastM = '30d',
  thisM = 'thism',
  prevM = 'prevm',
  thisY = 'thisy',
  prevY = 'prevy',
}

export type ViewParams = {
  dataGroup: string[]
  dataColumns?: string[]
  periodGroup: Period
  period?: string
  tz: string
};

export enum StatisticViewAvail {
  content = 'content',
  filter = 'filter',
  sorting = 'sorting',
  columns = 'columns',
}
export type StatisticView = {
  color?: string
  content?: ViewParams
  filter?: GridFilterInitialState
  sorting?: GridSortingInitialState
  columns?: GridColumnsInitialState
  avail: StatisticViewAvail[]
};

export type StatisticViewForm = {
  viewId?: number
  title?: string
  color?: string
  [StatisticViewAvail.sorting]?: boolean
  [StatisticViewAvail.filter]?: boolean
  [StatisticViewAvail.columns]?: boolean
  [StatisticViewAvail.content]?: boolean
};

export type StatisticViewFormValue = {
  periodGroup: Period
  interval: DateRangeValue
  tz: string
  generalColumns: StatisticColumn[]
  customersColumns?: StatisticColumn[]
  messagesColumns?: StatisticColumn[]
  billingColumns?: StatisticColumn[]
  billingCountersColumns?: StatisticColumn[]
};

export type StructGetter = (groups?: ColumnGroup | ColumnGroup[]) => StatisticColumn[];
