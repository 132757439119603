import { Box, Button, CircularProgress } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useRequest } from '@xeebi/neru';
import exportData from 'shared-scope/helpers/exportData';
import { s } from 'i18n';

const exportMessageStatistic = (variables: any) => {
  return exportData('statistics', variables);
};

const exportBillingStatistic = (variables: any) => {
  return exportData('bill-statistics', variables);
};

export default function Export({ params = {}, title = s('Download'), type }: ExportProps) {
  const {
    isLoading,
    error,
    fetch: fetchExport,
    cancel,
  } = useRequest(type === 'billing' ? exportBillingStatistic : exportMessageStatistic);

  useEffect(() => {
      error && console.error(error.getError());
  }, [error]);

  const exportStat = useCallback(async () => {
    if (isLoading) {
      cancel(s('Duplicate request'));
    }
    const fetchParams = params instanceof Function ? params() : params;
    await fetchExport(fetchParams, {});
  }, [params, isLoading, fetchExport, cancel]);

  return <>
    <Box sx={{ position: 'relative', ml: 'auto' }}>
      <Button
        size="small"
        startIcon={isLoading ? <CircularProgress size={18} color="secondary" /> : <i className="icon-import" />}
        onClick={() => exportStat()}
        disabled={isLoading}
      >
        {title}
      </Button>
    </Box>
    {/*<Snackbar open={!!error} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>*/}
    {/*  <Alert severity="error" onClose={() => clearError()}>{error && error.getMessage()}</Alert>*/}
    {/*</Snackbar>*/}
  </>;
}

type ExportProps = {
  params?: Record<string, any> | (() => Record<string, any>)
  title?: string
  type: 'billing' | 'messages'
};
