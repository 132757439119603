import { useLocalStorage } from '@xeebi/neru';
import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Maybe } from 'graphql-api';
import {
  ColumnGroup,
  DateRangeValue,
  Period,
  ShortcutValue,
  StatisticViewFormValue,
  StructGetter,
  ViewParams,
} from 'products/Account/Tabs/Statistics/types';
import StatisticViewForm from 'products/Account/Tabs/Statistics/components/StatisticViewForm';
import { getColumns, getFieldsList, getIntervalDate } from 'products/Account/Tabs/Statistics/helpers';


dayjs.extend(customParseFormat);

export default function useStatisticForm(getStruct: StructGetter, dateFormat: string) {
  const defaultColumns: StatisticViewFormValue = useMemo(
    () => ({
      periodGroup: Period.hour,
      interval: ShortcutValue.h24,
      tz: '',
      generalColumns: [],
      customersColumns: [],
      messagesColumns: getColumns(getStruct(), ['sentCount', 'clickCount']),
      billingColumns: getColumns(getStruct(), ['totalAmount']),
    }),
    [getStruct],
  );

  const [
    statFormRow,
    setStatFormRow,
  ] = useLocalStorage<StatisticViewFormValue>('Statistic.View', defaultColumns);

  const dataGroup = useMemo(() => [
    ...getFieldsList(statFormRow.generalColumns),
    ...getFieldsList(statFormRow.customersColumns),
  ], [statFormRow.generalColumns, statFormRow.customersColumns]);

  const viewParams: ViewParams = useMemo(() => ({
    dataGroup,
    dataColumns: getFieldsList(statFormRow.messagesColumns).concat(getFieldsList(statFormRow.billingColumns)),
    periodGroup: statFormRow.periodGroup,
    period: Array.isArray(statFormRow.interval)
      ? getIntervalDate(statFormRow.interval, dateFormat)?.join('; ')
      : statFormRow.interval,
    tz: statFormRow.tz,
  }), [dataGroup, statFormRow.messagesColumns, statFormRow.billingColumns, statFormRow.periodGroup, statFormRow.interval, statFormRow.tz, dateFormat]);

  const setViewParams = useCallback((params: Maybe<ViewParams>) => {
    if (!params) {
      setStatFormRow(defaultColumns);
      return;
    }

    const period = params.period?.split('; ') || [defaultColumns.interval];
    let interval: DateRangeValue;
    if (period.length > 1) {
      interval = [dayjs(period[0] as string, dateFormat), dayjs(period[1] as string)];
    } else {
      interval = period[0] as ShortcutValue;
    }
    const formRow = {
      periodGroup: params.periodGroup,
      interval,
      tz: params.tz,
      messagesColumns: getColumns(getStruct(), params.dataColumns),
      generalColumns: getColumns(getStruct(ColumnGroup.general), params.dataGroup),
      customersColumns: getColumns(getStruct([ColumnGroup.technologies, ColumnGroup.geo]), params.dataGroup),
      billingColumns: getColumns(getStruct(ColumnGroup.billing), params.dataGroup),
    };

    setStatFormRow(formRow);
  }, [defaultColumns, dateFormat, getStruct, setStatFormRow]);

  return {
    dataGroup,
    viewParams,
    statFormRow,
    setViewParams,
    statFormRender: <StatisticViewForm value={statFormRow} onChange={setStatFormRow} getStruct={getStruct} type="messages" />,
  };
}

