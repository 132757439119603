import { Stack, Typography } from '@mui/material';
import React, {
  useCallback, useEffect,
} from 'react';
import { s } from 'i18n';
import MappingParam from './MappingParam';
import { MappingFormField, MappingFormValue } from '../types';

const DEFAULTS: Record<MappingFormField, any> = {
  [MappingFormField.cutDigits]: 0,
  [MappingFormField.addPrefix]: '',
  [MappingFormField.sheets]: 0,
  [MappingFormField.csvDelimiter]: ',',
  [MappingFormField.csvQuote]: '"',
  [MappingFormField.skipTop]: 0,
  [MappingFormField.random]: 0,
};

export default function MappingForm(
  {
    fields,
    value: formValue,
    onChange,
    showRandom = true,
  }: MappingFormProps) {
  const handleParam = useCallback((v: string | number, f: MappingFormField) => {
    onChange({ ...formValue, [f]: v });
  }, [onChange, formValue]);

  useEffect(() => {
    onChange(DEFAULTS);
  }, [onChange]);

  return (
    <Stack spacing={1}>
      {
        (fields.includes(MappingFormField.addPrefix) || fields.includes(MappingFormField.cutDigits))
        && <Typography variant="h4">{s('Phone number')}</Typography>
      }
      {
        fields.includes(MappingFormField.cutDigits)
        && <MappingParam<number>
          title={s('Cut digits')}
          value={formValue.cutDigits || DEFAULTS.cutDigits}
          type="number"
          inputProps={{ inputProps: { min: 0 } }}
          inputWidth="180px"
          onChange={(v) => handleParam(v, MappingFormField.cutDigits)}
        />
      }
      {
        fields.includes(MappingFormField.addPrefix)
        && <MappingParam<string>
          title={s('Add prefix')}
          value={formValue.addPrefix || DEFAULTS.addPrefix}
          inputWidth="180px"
          onChange={(v) => handleParam(v, MappingFormField.addPrefix)}
        />
      }
      <Typography variant="h4">{s('Phone list')}</Typography>
      {fields.includes(MappingFormField.sheets)
        && <MappingParam<number>
          title={s('Sheet')}
          value={formValue.sheet || DEFAULTS.sheet}
          type={/*initial.sheets*/[]}
          inputWidth="180px"
          onChange={(v) => handleParam(v, MappingFormField.sheets)}
        />}
      {fields.includes(MappingFormField.csvDelimiter)
        && <MappingParam<string>
          title={s('Delimiter')}
          value={formValue.delimiter || DEFAULTS.delimiter}
          type={[
          { k: ',', v: ',' },
          { k: ':', v: ':' },
          { k: ';', v: ';' },
          { k: '\t', v: 'TAB' },
          { k: ' ', v: 'SPACE' },
          { k: '|', v: '|' },
        ]}
          inputWidth="180px"
          onChange={(v) => handleParam(v, MappingFormField.csvDelimiter)}
        />}
      {fields.includes(MappingFormField.csvQuote)
        && <MappingParam<string>
          title={s('Quote character')}
          value={formValue.quoteCharacter || DEFAULTS.quoteCharacter}
          type={[
          { k: '\'', v: '\'' },
          { k: '"', v: '"' },
        ]}
          inputWidth="180px"
          onChange={(v) => handleParam(v, MappingFormField.csvQuote)}
        />}
      {fields.includes(MappingFormField.skipTop)
        && <MappingParam<number>
          title={s('Skip top rows')}
          value={formValue.skipTopRows || DEFAULTS.skipTopRows}
          type="number"
          inputProps={{ inputProps: { min: 0 } }}
          inputWidth="180px"
          onChange={(v) => handleParam(v, MappingFormField.skipTop)}
        />}
      {(fields.includes(MappingFormField.random) && showRandom)
        && <MappingParam<number>
          title={s('Count of random messages')}
          value={formValue.random || DEFAULTS.random}
          type="number"
          inputProps={{ inputProps: { min: 0 } }}
          inputWidth="200px"
          helperText={s('\'0\' to send to all numbers')}
          onChange={(v) => handleParam(v, MappingFormField.random)}
        />}
    </Stack>
  );
}

type MappingFormProps = {
  fields: MappingFormField[]
  value: MappingFormValue
  onChange: (v: MappingFormValue) => void
  showRandom?: boolean
};
