import * as CONFIG from 'shared-scope/config';
import axios from 'axios';

export default async function exportData(exportName: string, params: Record<string, any>, exportUrl?: string) {
  const baseUrl = exportUrl || `${CONFIG.get('api')}/export/${exportName}`;

  const execResp = await axios.get(
    `${baseUrl}`,
    {
      responseType: 'blob',
      params,
    },
  );

  const fd = (new Date()).format('dd_mm_yyyy_H2_M2');
  const href = URL.createObjectURL(execResp?.data);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `export_${exportName}_${fd}.xlsx`);
  link.click();
  URL.revokeObjectURL(href);
}
