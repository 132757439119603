import { Grid, Stack, Typography } from '@mui/material';
import { s } from 'i18n';
import { useCallback } from 'react';
import { ControlledComponent } from 'shared-scope/types';
import {
  ColumnGroup,

  StatisticColumn,
  StatisticViewFormValue,
  StructGetter,
} from '../types';
import {
 ColumnsSelect, IntervalRange, PeriodToggle, TimezoneSelect,
} from '.';

export default function StatisticViewForm({
  value,
  onChange,
  getStruct,
  type,
}: StatisticViewFormProps) {
  const handleViewChange = useCallback(
    (v: Partial<StatisticViewFormValue>) => onChange({ ...value, ...v }),
    [value, onChange],
  );

  const handleMessagesColumns = useCallback(
    (v: StatisticColumn[]) => v && v.length && handleViewChange({ messagesColumns: v }),
    [handleViewChange],
  );
  const isMessageStatistic = type === 'messages';
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="space-between"
      alignItems="start"
      sx={{ width: '100%' }}
    >
      <Grid item xs={12} xl={6}>
        <Stack spacing={1}>
          <Typography variant="h5">{s('Show columns')}</Typography>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} md={6}>
              <ColumnsSelect
                title={s('General')}
                options={getStruct(ColumnGroup.general)}
                value={value.generalColumns}
                onChange={(v) => handleViewChange({ generalColumns: v })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {isMessageStatistic
                ? (
                  <ColumnsSelect
                    title={s('Customers')}
                    options={getStruct([ColumnGroup.geo, ColumnGroup.technologies])}
                    value={value.customersColumns || []}
                    onChange={(v) => handleViewChange({ customersColumns: v })}
                  />
                )
                : (
                  <ColumnsSelect
                    title={s('Amounts')}
                    options={getStruct([ColumnGroup.billingCounters])}
                    value={value.billingCountersColumns || []}
                    onChange={(v) => handleViewChange({ billingCountersColumns: v })}
                  />
                )}

            </Grid>
            <Grid item xs={12} md={6}>
              {isMessageStatistic
                ? (
                  <ColumnsSelect
                    title={s('Messages')}
                    options={getStruct([ColumnGroup.successMessages, ColumnGroup.errorMessages])}
                    value={value.messagesColumns || []}
                    onChange={handleMessagesColumns}
                    required
                  />
                )
                : null}
            </Grid>
            <Grid item xs={12} md={6}>
              {isMessageStatistic
                ? (
                  <ColumnsSelect
                    title={s('Billing')}
                    options={getStruct([ColumnGroup.billing])}
                    value={value.billingColumns || []}
                    onChange={(v) => handleViewChange({ billingColumns: v })}
                  />
                )
                : null}
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={12} xl={6}>
        <Stack spacing={1} direction="column">
          <Typography variant="h5">{s('Interval')}</Typography>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} md={4}>
              <PeriodToggle value={value.periodGroup} onChange={(v) => handleViewChange({ periodGroup: v })} />
            </Grid>
            <Grid item xs={12} md={4}>
              <IntervalRange value={value.interval} onChange={(v) => handleViewChange({ interval: v })} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TimezoneSelect value={value.tz} onChange={(v) => handleViewChange({ tz: v })} />
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
}

type StatisticViewFormProps = ControlledComponent<StatisticViewFormValue> & {
  getStruct: StructGetter
  type: 'messages' | 'billing'
};
